import { CommonModule } from '@angular/common';
import { Component, computed, DestroyRef, inject, input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { getDeviceAttributesByType } from '@app-lib';
import { DirectivesModule } from '@directives';
import { DeviceAttributeType, DeviceData, EntityStatus, IncidentStatus } from '@models';
import { Store } from '@ngrx/store';
import { AppState, isDeviceUpdating, rebootDevice } from '@ngrx-store';
import { ConfirmationDialogComponent } from '@standalone/_modals/confirmation-dialog/confirmation-dialog.component';
import { ImgLoaderComponent } from '@standalone/img-loader/img-loader.component';
import { IncidentStatusComponent } from '@standalone/incident-status/incident-status.component';
import { Observable } from 'rxjs';

@Component({
  imports: [MatCardModule, ImgLoaderComponent, IncidentStatusComponent, MatIconModule, MatProgressSpinner, DirectivesModule, CommonModule],
  selector: 'app-mobile-device-info',
  standalone: true,
  templateUrl: './device-info.component.html',
})
export class DeviceInfoComponent {
  device = input.required<DeviceData>();
  clientId = input.required<string>();

  protected readonly eIncidentStatus = IncidentStatus;
  protected propertyAttributes = computed(() => {
    const additionalAttributes = this.device().additionalAttributes;

    return additionalAttributes ? getDeviceAttributesByType(
      additionalAttributes,
      DeviceAttributeType.PROPERTY,
    ) : [];
  });
  protected deviceIpAddress = computed(() => this.device().tags?.['ipAddress'] + '/' + (this.device().tags?.['hyperlink'] || ''));
  protected tempSensor = computed(() => this.device().additionalAttributes?.find(attribute => attribute.name === 'tempSensor')?.value);
  protected destroyRef = inject(DestroyRef);
  protected dialog = inject(MatDialog);
  protected deviceIsUpdating$: Observable<boolean>;
  protected readonly EntityStatus = EntityStatus;

  private store = inject(Store<AppState>);

  constructor() {
    this.deviceIsUpdating$ = this.store.select(isDeviceUpdating);
  }

  protected rebootDevice() {
    ConfirmationDialogComponent.open(this.dialog, {
      description: 'Are you sure you want to reboot the device? ',
      title: 'Reboot Device',
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(confirmation => {
        if (confirmation) {
          this.store.dispatch(rebootDevice({ deviceId: this.device().id, locationId: this.device().location.id }));
        }
      });
  }
}
