import { Component, computed, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NavigationEnd, Router } from '@angular/router';
import { LocationData, ScreenOrientations } from '@models';
import { select, Store } from '@ngrx/store';
import { AppState, areLocationsLoaded, selectAllLocations } from '@ngrx-store';
import { AppService, BuildingDataFilterService, MapFilterService } from '@services';
import { Observable } from 'rxjs';

import {
  BuildingFiltersSheetComponent,
} from '../../components/building-filters-sheet/building-filters-sheet.component';
import { MapFiltersSheetComponent } from '../../components/map-filters-sheet/map-filters-sheet.component';

type DashboardTab = 'table' | 'map';

@Component({
  selector: 'app-mobile-dashboard-main',
  templateUrl: './dashboard-main.component.html',
})

export class DashboardMainComponent {

  tab: DashboardTab = 'table';
  isFullScreen = false;
  locationsLoaded$: Observable<boolean>;

  protected selectedFilters = computed(() =>
    !!(this.buildingDataFilterService.statusFilter || this.buildingDataFilterService.incidentFilter?.length),
  );

  protected readonly ScreenOrientations = ScreenOrientations;
  protected destroyRef = inject(DestroyRef);
  protected locationsData$: Observable<LocationData[]>;

  constructor(
    private bottomSheet: MatBottomSheet,
    public buildingDataFilterService: BuildingDataFilterService,
    public mapFilterService: MapFilterService,
    private store: Store<AppState>,
    public appService: AppService,
    router: Router,
  ) {
    this.locationsLoaded$ = this.store.select(areLocationsLoaded);
    this.locationsData$ = this.store.pipe(select(selectAllLocations));
    router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(event => {
      if (event instanceof NavigationEnd) this.tab = 'table';
    });
  }

  openBuildingFiltersBottomSheet(): void {
    this.bottomSheet.open(BuildingFiltersSheetComponent);
  }

  openMapFiltersBottomSheet(): void {
    this.bottomSheet.open(MapFiltersSheetComponent);
  }
}
