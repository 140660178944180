import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { RouterLink } from '@angular/router';
import { rooms } from '@mocks';
import { FloorSpaceModel, LocationFullData } from '@models';
import { PipesModule } from '@pipes';
import { BottomSheetMobileHeaderComponent } from '@standalone/bottom-sheet-mobile-header/bottom-sheet-mobile-header.component';
import { SearchInputComponent } from '@standalone/search-input/search-input.component';

@Component({
  imports: [
    BottomSheetMobileHeaderComponent,
    CommonModule,
    PipesModule,
    RouterLink,
    SearchInputComponent,
  ],
  selector: 'avs-mo-room-list-sheet',
  standalone: true,
  templateUrl: './room-list-sheet.component.html',
})
export class RoomListSheetComponent {
  protected search = '';
  protected readonly rooms = rooms;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    protected data: {
      currentFloor: FloorSpaceModel;
      locationData: LocationFullData;
      roomId: string;
    },
    private bottomSheetRef: MatBottomSheetRef<RoomListSheetComponent>,
  ) { }

  protected close() {
    this.bottomSheetRef.dismiss();
  }

  protected openRoom() {
    this.close();
  }
}
