import { CdkScrollable } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { importProvidersFrom, isDevMode, NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppInterceptor } from '@app-lib';
import {
  MainMenuMobileComponent,
  MoreMobileMenuSheetComponent,
} from '@app-mobile/modules/main/components/main-menu-mobile/main-menu-mobile.component';
import { WebSocketEffects } from '@core';
import { FiltersStore } from '@devices';
import { ENVIRONMENT,environment } from '@env';
import { popperVariation, provideTippyConfig, tooltipVariation } from '@ngneat/helipopper';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  AppSettingsEffects,
  AuthEffects,
  ClientsManagementsEffects,
  ClientUsersEffects,
  DevicesEffects,
  IncidentsEffects,
  LocationsEffects,
  metaReducers,
  reducers,
} from '@ngrx-store';
import {
  BottomSheetMobileHeaderComponent,
} from '@standalone/bottom-sheet-mobile-header/bottom-sheet-mobile-header.component';
import { CopyrightFooterComponent } from '@standalone/copyright-footer/copyright-footer.component';
import { MobileMainHeaderComponent } from '@standalone/mobile-main-header/mobile-main-header.component';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { routes } from './app.routes';

@NgModule({
  declarations: [AppComponent, MainMenuMobileComponent, MoreMobileMenuSheetComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    CopyrightFooterComponent,
    NgxMaskDirective,
    NgxMaskPipe,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictActionSerializability: false,
        strictStateSerializability: true,
      },
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode(), connectInZone: true }),
    EffectsModule.forRoot([
      LocationsEffects,
      AuthEffects,
      AppSettingsEffects,
      ClientUsersEffects,
      DevicesEffects,
      IncidentsEffects,
      ClientsManagementsEffects,
      WebSocketEffects,
    ]),
    ToastrModule.forRoot({
      enableHtml: true,
      positionClass: 'toast-bottom-center',
      disableTimeOut: false,
      timeOut: 3000,
      preventDuplicates: true,
    }),
    MatIcon,
    MatListModule,
    MobileMainHeaderComponent,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    BottomSheetMobileHeaderComponent,
    CdkScrollable,
  ],
  providers: [
    FiltersStore,
    provideEnvironmentNgxMask(),
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,
    },
    provideTippyConfig({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
      },
    }),
    importProvidersFrom(MatNativeDateModule),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
}
