<div class="flex items-center justify-between mb-2">
  <h1 class="mobile-page-main-title">Devices</h1>
  <button
    class="test-open-export-devices-button btn-blue btn-medium w-10 h-10"
    (click)="openExportDevicesBottomSheet()"
  >
    <i class="icon-upload-map text-3xl"></i>
  </button>
</div>

<avs-li-mobile-devices-filters
  (filtersChanged)="filters.set($event)"
  [tableId]="TABLE_ID"
/>

<avs-li-devices-table
  class="overflow-hidden"
  [config]="{ isMobile: true }"
  [displayedColumns]="displayedColumns"
  [filters]="filters()"
  [tableId]="TABLE_ID"
  #devicesTable
/>
