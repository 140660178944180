import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, LoginGuard } from '@app-lib';
import { AuthModule } from '@app-mobile/modules/auth/auth.module';
import { DashboardModule } from '@app-mobile/modules/dashboard/dashboard.module';
import { DevicesModule } from '@app-mobile/modules/devices/devices.module';
import { IncidentsModule } from '@app-mobile/modules/incidents/incidents.module';
import { ReportingModule } from '@app-mobile/modules/reporting/reporting.module';
import { UserManagementModule } from '@app-mobile/modules/user-management/user-management.module';
import { UserProfileModule } from '@app-mobile/modules/user-profile/user-profile.module';
import { DashboardPageComponent } from '@dashboards';
import { Error404Component } from '@standalone/error-404/error-404.component';

export const routes: Routes = [
  {
    canActivate: [LoginGuard],
    loadChildren: () => import('@app-mobile/modules/auth/auth.module').then(() => AuthModule),
    path: '',
  },
  {
    canActivate: [AuthGuard],
    data: { showMenu: false },
    loadChildren: () => import('@app-mobile/modules/user-profile/user-profile.module').then(() => UserProfileModule),
    path: 'user-profile',
  },
  {
    canActivate: [AuthGuard],
    loadChildren: () => import('@app-mobile/modules/dashboard/dashboard.module').then(() => DashboardModule),
    path: 'dashboard',
  },
  {
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Dashboards' },
    loadComponent: () => import('@dashboards')
      .then(() => DashboardPageComponent),
    path: 'dashboards/:dashboardId',
  },
  {
    canActivate: [AuthGuard],
    loadChildren: () => import('@app-mobile/modules/devices/devices.module').then(() => DevicesModule),
    path: 'devices',
  },
  {
    canActivate: [AuthGuard],
    loadChildren: () => import('@app-mobile/modules/incidents/incidents.module').then(() => IncidentsModule),
    path: 'incidents',
  },
  {
    canActivate: [AuthGuard],
    loadChildren: () => import('@app-mobile/modules/reporting/reporting.module').then(() => ReportingModule),
    path: 'reporting',
  },
  {
    path: 'user-management',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@app-mobile/modules/user-management/user-management.module').then(() => UserManagementModule),
  },
  {
    component: Error404Component,
    data: {
      isErrorPage: true,
      isMobile: true,
    },
    path: '**',
    pathMatch: 'full',
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)],
})
export class AppRoutingModule {}
