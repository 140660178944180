import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCard } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { DevicesFiltersV2Component, DevicesTableV2Component, ExportDevicesSheetV2Component, MobileDevicesFiltersComponent } from '@devices';
import { ENVIRONMENT,environment } from '@env';
import { deviceDetailsResolver, deviceResolver } from '@ngrx-store';
import { IsFeatureEnabledPipe, PipesModule } from '@pipes';
import { IncidentsTableComponent } from '@standalone/_tables/incidents-table/incidents-table.component';
import { BottomSheetMobileHeaderComponent } from '@standalone/bottom-sheet-mobile-header/bottom-sheet-mobile-header.component';
import { DeviceStatsChartComponent } from '@standalone/device-stats-chart/device-stats-chart.component';
import { EntityStatusComponent } from '@standalone/entity-status/entity-status.component';
import { ImgLoaderComponent } from '@standalone/img-loader/img-loader.component';
import { IncidentStatusComponent } from '@standalone/incident-status/incident-status.component';
import { MobileButtonsBarComponent } from '@standalone/mobile-buttons-bar/mobile-buttons-bar.component';
import { NestedPageHeaderComponent } from '@standalone/nested-page-header/nested-page-header.component';
import { SearchInputComponent } from '@standalone/search-input/search-input.component';
import { SkeletonTableComponent } from '@standalone/skeleton-table/skeleton-table.component';
import { SpaceSkeletonAnimationComponent } from '@standalone/space-skeleton-animation/space-skeleton-animation.component';

import { DeviceInfoComponent } from './components/device-info/device-info.component';
import { DeviceDetailsComponent } from './containers/device-details/device-details.component';
import { DevicesMainPageComponent } from './containers/devices-main-page/devices-main-page.component';

@NgModule({
  declarations: [DevicesMainPageComponent, DeviceDetailsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        component: DevicesMainPageComponent,
        data: { showMenu: true },
        path: '',
        resolve: { deviceResolver },
      },
      {
        component: DeviceDetailsComponent,
        data: { showMenu: false },
        path: ':locationId/:deviceId',
        resolve: { deviceDetailsResolver },
      },
    ]),
    MatExpansionModule,
    MatCheckboxModule,
    PipesModule,
    NestedPageHeaderComponent,
    MobileButtonsBarComponent,
    SpaceSkeletonAnimationComponent,
    MatCard,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    SkeletonTableComponent,
    SearchInputComponent,
    ImgLoaderComponent,
    BottomSheetMobileHeaderComponent,
    EntityStatusComponent,
    IncidentStatusComponent,
    DeviceStatsChartComponent,
    IncidentsTableComponent,
    IsFeatureEnabledPipe,

    // V2 tables
    DevicesTableV2Component,
    DevicesFiltersV2Component,

    ExportDevicesSheetV2Component,
    MobileDevicesFiltersComponent,
    DeviceInfoComponent,
  ],
  providers: [
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
  ],
})
export class DevicesModule {}
