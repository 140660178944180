@if (incident) {
  @let firmware = incident.device['tags']?.['firmware'];
  <app-mobile-nested-page-header
    [title]="incident.incidentNumber.toString()"
    titleName="Ticket №:"
  />

  <mat-card class="card">
    <ul class="main-details-list">
      <li class="flex items-center">
        <i class="icon-ic_building text-3xl mr-3 text-ui-gray-100"></i>
        <a
          class="underline"
          [routerLink]="['/dashboard', incident.device.location.id]"
        >
          <strong>{{ incident.device.location.friendlyName }}</strong>
        </a>
      </li>
      <li class="flex items-center">
        <i class="icon-ic_location text-3xl mr-3 text-ui-gray-100"></i>
        {{ incident.device.location.address.streetAddress + ', ' + incident.device.location.address.city }}
      </li>
      <li class="flex items-center">
        <i class="icon-ic_status text-3xl mr-3 text-ui-gray-100"></i>
        <app-entity-status
          [status]="incident.device.status"
          fontSize="base"
        />
      </li>
    </ul>
  </mat-card>

  <mat-card class="card divider-card mt-3">
    <div>
      <div>Floor:</div>
      <div>
        <a
          class="underline text-xl font-semibold"
          [routerLink]="['/dashboard', incident.device.location.id, incident.device.spacePath?.[0]?.id]"
        >{{ incident.device.spacePath?.[0]?.friendlyName }}</a>
      </div>
    </div>
    <div class="divider">
      <div></div>
      <div class="line-block"></div>
      <div></div>
    </div>
    <div>
      <div>Room:</div>
      <div>
        <a
          class="underline text-xl font-semibold"
          [routerLink]="['/dashboard', incident.device.location.id, incident.device.spacePath?.[0]?.id, incident.device.spacePath?.[1]?.id]"
        >{{ incident.device.spacePath?.[1]?.friendlyName }}</a>
      </div>
    </div>
  </mat-card>

  <div class="border-b-ui-gray-light border-b flex items-center justify-between my-3">
    <mat-button-toggle-group
      class="mobile-toggle-group"
      (change)="selectedTab = $event.value"
      [value]="selectedTab"
      aria-label="Incident Tabs"
    >
      <mat-button-toggle
        class="test-ticket-tab-btn"
        value="ticket"
      >
        <span class="item-status-title">Ticket Info</span>
      </mat-button-toggle>
      <mat-button-toggle
        class="test-device-tab-btn"
        value="device"
      >
        <span class="item-status-title">Device Info</span>
      </mat-button-toggle>
      @if (incident.status !== IncidentStatus.RESOLVED) {
        <mat-button-toggle
          class="test-notes-tab-btn"
          value="notes"
        >
          <span class="item-status-title">Notes</span>
        </mat-button-toggle>
      }
    </mat-button-toggle-group>
  </div>
  <div
    class="test-ticket-tab card"
    [class]="selectedTab === Tab.TICKET ? 'visible' : 'is-hidden-tab'"
  >
    <mat-card class="card">
      <h4 class="font-poppins text-2xl text-ui-dark font-semibold mb-4">{{ incident.incidentNumber }}</h4>
      <ul class="incident-details-list">
        <li>
          <span>Incident Status:</span>
          <p>
            <app-incident-status [status]="incident.status"/>
          </p>
        </li>
        <li>
          <span>Fault Name:</span>
          <p>{{ incident.fault.name }}</p>
        </li>
        <li>
          <span>Fault Description:</span>
          <p>{{ incident.fault.description }}</p>
        </li>
      </ul>
    </mat-card>
  </div>
  <div
    class="test-device-tab card"
    [class]="selectedTab === Tab.DEVICE ? 'visible' : 'is-hidden-tab'"
  >
    <mat-card class="card">
      <h4 class="font-poppins text-2xl text-ui-dark font-semibold mb-4">
        <a
          class="underline"
          [routerLink]="['/devices', incident.device.location.id, incident.device.id]"
        >{{
        incident.device.friendlyName
      }}</a>
      </h4>

      <div>
        @if(incident.device.deviceModelInformation && incident.device.deviceModelInformation.documentReference) {
          <app-img-loader
            [imgSrc]="
            'clientId/' +
            appService.currentClient +
            '/library/standardMake/' +
            incident.device.deviceModelInformation.make.id +
            '/standardModel/' +
            incident.device.deviceModelInformation.id +
            '/document/' +
            incident.device.deviceModelInformation.documentReference
          "
            altText="device"
            errorSrc="assets/icons/image-error.svg"
            loader="skeleton"
          />
        }
        @if (!incident.device.deviceModelInformation?.documentReference) {
          <img
            alt="Device Image"
            src="assets/icons/image-placeholder.svg"
          />
        }
      </div>

      <ul class="incident-details-list mb-3">
        <li>
          <span>Device type:</span>
          <p>{{ incident.device.deviceModelInformation?.deviceType }}</p>
        </li>
        <li>
          <span>Manufacturer: </span>
          <p>{{ incident.device.deviceModelInformation?.make?.name }}</p>
        </li>
        <li>
          <span>Model: </span>
          <p>{{ incident.device.deviceModelInformation?.name }}</p>
        </li>
        <li>
          <span>Serial №: </span>
          <p>{{ incident.device.physicalDeviceId }}</p>
        </li>

        @if (firmware) {
          <li>
            <span>Firmware: </span>
            <p>{{ firmware }}</p>
          </li>
        }

        <li>
          <span>Total Device Incidents: </span>
          <p>No data in response</p>
        </li>
      </ul>
      <a
        class="btn-medium btn-green w-full"
        [queryParams]="{ tab: 'incidents' }"
        [routerLink]="['/devices', incident.device.location.id, incident.device.id]"
      >Show all device incidents</a>
    </mat-card>
  </div>
  <div
    class="test-notes-tab incident-notes"
    [class]="selectedTab === Tab.NOTES ? 'visible' : 'is-hidden-tab'"
  >
    <mat-form-field class="w-full input-main bg-light h-auto">
      <textarea
        [(ngModel)]="noteDescription"
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMaxRows="5"
        cdkAutosizeMinRows="1"
        cdkTextareaAutosize
        matInput
        placeholder="Add Comment..."
      ></textarea>
    </mat-form-field>
    @if (noteDescription) {
      <div class="mb-6 flex items-center">
        <button
          class="test-save-note-btn btn-green btn-small mr-2"
          (click)="addNote()"
          [disabled]="!noteDescription.trim().length || postNoteLoading"
        >
        @if (postNoteLoading) {
          <mat-spinner
            class="mr-1"
            color="primary"
            diameter="20"
          ></mat-spinner>
        }
          Save
        </button>
        <button
          class="test-note-clear-btn px-4 py-2"
          (click)="noteDescription = ''"
        >Cancel</button>
      </div>
    }

    @for (note of notes; track $index) {
      <div class="mb-6">
        <div class="flex justify-between items-center mb-3">
          @if (note.user) {
            <strong class="text-ui-dark font-nunito text-xl">{{ note.user.lastName }} {{ note.user.firstName }}</strong>
            <span class="font-nunito text-ui-gray-100 text-sm">{{ note.timeStamp | date : 'medium' }}</span>
          }
        </div>
        <p class="text-base text-ui-gray-100 font-nunito">
          {{ note.textValue }}
        </p>
      </div>
    }
  </div>
} @else {
  <app-mobile-space-skeleton-animation class="test-skeleton-animation"/>
}
