@if (appService.screenOrientation === ScreenOrientations.PORTRAIT) {
  <h1 class="mobile-page-main-title mb-3">Dashboard</h1>
}

@if ('useDashboardsBuilder' | isFeatureEnabled) {
  <avs-li-mobile-dashboards-selector />
}

@if (locationsLoaded$ | async) {
  <div class="test-locations-table-wrapper h-full">
    <div class="border-b-ui-gray-light border-b flex items-center justify-between mb-3">
      <mat-button-toggle-group
        class="mobile-toggle-group"
        (change)="tab = $event.value"
        [value]="tab"
        aria-label="Dashboard Tabs"
      >
        <mat-button-toggle
          class="test-table-toggle-button"
          value="table"
        >
          <span class="item-status-title">Table View</span>
        </mat-button-toggle>
        <mat-button-toggle
          class="test-map-toggle-button"
          value="map"
        >
          <span class="item-status-title">Map View</span>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <button
        class="toggle-table-filter-btn test-open-building-filters"
        (click)="openBuildingFiltersBottomSheet()"
        [class.has-selected-filters]="selectedFilters()"
        *ngIf="tab === 'table'"
      >
        <i class="icon-filter_Menu text-3xl"></i>
      </button>
      <button
        class="toggle-table-filter-btn test-open-map-filters"
        (click)="openMapFiltersBottomSheet()"
        [class.has-selected-filters]="mapFilterService.mapFilter.length"
        *ngIf="tab === 'map'"
      >
        <i class="icon-filter_Menu text-3xl"></i>
      </button>
    </div>
    <div
      class="test-building-tab-wrapper"
      [hidden]="tab === 'map'"
    >
      <div class="mb-3">
        <app-search-input
          (searchDataEvent)="buildingDataFilterService.search = $event"
          inputWidth="100%"
          placeholder="Building name"
        />
      </div>
      <app-site-table />
    </div>

    <div
      class="test-map-tab-wrapper"
      [hidden]="tab === 'table'"
      [ngClass]="{
        'test-fullscreen-on fixed top-0 left-0 w-full h-full z-10': isFullScreen,
        'relative h-[calc(100%-150px)]': tab === 'map' && !isFullScreen,
        'relative h-[calc(100%-60px)]': tab === 'map' && !isFullScreen && !(appService.screenOrientation === ScreenOrientations.PORTRAIT),
  }"
    >
      <button
        class="test-exit-fullscreen toggle-table-filter-btn rounded-full absolute left-4 top-4 z-10"
        (click)="isFullScreen = false"
        *ngIf="isFullScreen"
      >
        <i class="icon-arrow-left text-3xl"></i>
      </button>
      <button
        class="test-enter-fullscreen toggle-table-filter-btn absolute right-4 top-4 z-10"
        (click)="isFullScreen = true"
        *ngIf="!isFullScreen"
      >
        <i class="icon-ic_zoom1 text-3xl"></i>
      </button>
      @if (tab === 'map' && (locationsData$ | async); as locationsData) {
        <avs-li-map [locations]="locationsData" />
      }
    </div>
  </div>
} @else {
  <app-skeleton-table
    class="test-skeleton-table-loader"
    [showTabs]="false"
  />
}
