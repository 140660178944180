import { SpaceDetailsClass } from '@abstraction';
import { Component, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  DeviceData,
  DeviceTableCols,
  EntityStatus,
  FloorSpaceModel,
  RoomSpaceModel,
  ScreenOrientations,
  SpaceStats,
} from '@models';
import { Store } from '@ngrx/store';
import { AppState, getDeviceBySpaceId, loadDeviceCollectionBySpaceId, updateRoom } from '@ngrx-store';
import { AppService } from '@services';
import { AddExternalLinkModalComponent } from '@standalone/_modals/add-external-link-modal.component/add-external-link-modal.component';
import { Observable, of } from 'rxjs';

import { RoomListSheetComponent } from './room-list-sheet.component';

enum Tabs {
  DEVICES = 'devices',
  BLOCK_DIAGRAM = 'blockDiagram',
  INCIDENTS = 'incidents',
}

@Component({
  styleUrl: './room-details.component.scss',
  templateUrl: './room-details.component.html',
})
export class RoomDetailsComponent extends SpaceDetailsClass implements OnInit {
  currentFloor!: FloorSpaceModel | undefined;
  currentRoom!: RoomSpaceModel | undefined;
  roomStats!: SpaceStats;
  roomHasFloorPlan = false;
  roomHasIncidents = false;
  roomHasDevices = false;
  tab: Tabs = Tabs.DEVICES;

  protected devicesTableDisplayedColumns = [
    DeviceTableCols.INDEX,
    DeviceTableCols.DEVICE_NAME,
    DeviceTableCols.MANUFACTURER,
    DeviceTableCols.MODEL,
    DeviceTableCols.SERIAL_NUMBER,
    DeviceTableCols.DEVICE_TYPE,
    DeviceTableCols.STATUS,
    DeviceTableCols.INCIDENTS,
    DeviceTableCols.ACTIONS,
  ];
  protected filters = signal<Record<string, string | string[]> | null>(null);
  protected readonly ScreenOrientations = ScreenOrientations;
  protected readonly TABLE_ID = 'device-room-table';
  protected readonly Tabs = Tabs;
  protected readonly window = window;

  constructor(
    public override store: Store<AppState>,
    public override dialog: MatDialog,
    public override appService: AppService,
    public override route: ActivatedRoute,
    private bottomSheet: MatBottomSheet,
  ) {
    super(store, dialog, appService, route);
    this.routerWatcher();
    this.onLocationLoaded = () => {
      this.currentFloor = this.locationData.floors.find(floor => floor.id === this.floorId);
      this.updateFloorRoomData();
    };
  }

  ngOnInit() {
    this.getStoreData();
  }

  routerWatcher() {
    this.route.params.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(params => {
      this.roomId = params['roomId'];
      this.floorId = params['floorId'];
      this.updateFloorRoomData();
    });
  }

  updateFloorRoomData() {
    if (!this.locationData?.floors) return;
    if (this.currentFloor?.rooms) {
      this.currentRoom = this.currentFloor?.rooms.find(room => room.id === this.roomId);
      this.findAndObserveRoomDevices().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
      this.updateRoomStats();
      this.roomHasFloorPlan = !!(this.currentFloor.documentReference && this.currentRoom?.unstructuredDataReference);
      this.roomHasIncidents = !!(this.roomStats.incidentAlert || this.roomStats.incidentInProgress);
      this.roomHasDevices = !!(this.roomStats.deviceActive || this.roomStats.devicePaused);
      this.checkTabAvailability();
    }
  }

  pauseRoom() {
    const isActive = this.currentRoom?.status !== EntityStatus.Paused;
    const title = isActive ? 'Pause' : 'Resume';
    const description = `Are you sure you want to ${isActive ? 'pause' : 'resume'} the ${this.currentRoom?.friendlyName
      }?`;
    const status = isActive ? EntityStatus.Paused : EntityStatus.Active;

    if (this.currentRoom?.id) {
      this.toggleRoomStatus(title, description, status, this.currentRoom.id);
    }
  }

  openRoomList() {
    this.bottomSheet.open(RoomListSheetComponent, {
      data: {
        currentFloor: this.currentFloor,
        locationData: this.locationData,
        roomId: this.roomId,
      },
    });
  }

  openLinkEditModal() {
    this.dialog.open(AddExternalLinkModalComponent, {
      data: {
        link: this.currentRoom?.externalReference,
        locationId: this.locationId,
        spaceId: this.currentRoom?.id,
      },
    });
  }

  private checkTabAvailability() {
    const tabChecks: { [key: string]: boolean; } = {
      [Tabs.DEVICES]: this.roomHasDevices,
      [Tabs.BLOCK_DIAGRAM]: this.roomHasFloorPlan,
      [Tabs.INCIDENTS]: this.roomHasIncidents,
    };

    for (const tab in tabChecks) {
      if (tabChecks[tab]) {
        this.tab = tab as Tabs;
        break;
      }
    }
  }

  private findAndObserveRoomDevices(): Observable<DeviceData[]> {
    if (!this.locationData?.floors) {
      return of([]);
    }

    if (this.currentFloor?.rooms) {
      this.currentRoom = this.currentFloor?.rooms.find(room => room.id === this.roomId);

      if (this.currentRoom) {
        this.store.dispatch(
          loadDeviceCollectionBySpaceId({
            locationId: this.locationId,
            roomId: this.currentRoom.id,
          }),
        );

        return this.store.select(getDeviceBySpaceId(this.currentRoom.id));
      }
      this.updateRoomStats();
    }

    return of([]);
  }

  private updateRoomStats() {
    this.roomStats = {
      deviceActive: this.currentRoom?.devicesByStatuses.activeCount || 0,
      devicePaused: this.currentRoom?.devicesByStatuses.pausedCount || 0,
      floors: 0,
      incidentAlert: this.currentRoom?.incidentCountByStatuses.newCount || 0,
      incidentInProgress: this.currentRoom?.incidentCountByStatuses.inProgressCount || 0,
      rooms: 0,
    };
  }

  private toggleRoomStatus(title: string, description: string, status: EntityStatus, spaceId: string) {
    this.openConfirmationDialog({
      action: updateRoom({
        data: { status },
        locationId: this.locationData.id,
        spaceId,
      }),
      description,
      title,
    });
  }
}
