@if (locationData && currentRoom && currentFloor) {
  <header class="flex">
    <app-mobile-nested-page-header
      class="flex-grow"
      [backHomeLink]="true"
      [title]="currentRoom.friendlyName"
      titleName="Room"
    />
    <app-mobile-mobile-buttons-bar
      (editTrigger)="openLinkEditModal()"
      (linkTrigger)="window.open(currentRoom.externalReference, '_blank')"
      (statusTrigger)="pauseRoom()"
      [editEnabled]="true"
      [isArchived]="currentRoom.status === EntityStatus.Archived"
      [isPaused]="currentRoom.status === EntityStatus.Paused"
      [linkEnabled]="!!currentRoom.externalReference"
    />
  </header>

  <app-mobile-building-address-bar
    [buildingAddress]="locationData.address.streetAddress + ', ' + locationData.address.city"
    [buildingName]="locationData.friendlyName"
    [floorStatus]="currentRoom.status"
  />

  <div [class.flex]="appService.screenOrientation === ScreenOrientations.LANDSCAPE">
    <mat-card
      class="flex-grow card divider-card mt-3"
      [class.mr-2]="appService.screenOrientation === ScreenOrientations.LANDSCAPE"
    >
      <div>
        <div>Floor:</div>
        <div>
          <strong
            class="underline cursor-pointer"
            appBackButton
          >
            {{ currentFloor.friendlyName }}
          </strong>
        </div>
      </div>
      <div class="divider">
        <div></div>
        <div class="line-block"></div>
        <div></div>
      </div>
      <div>
        <div>Room:</div>
        <div
          class="false-dropdown"
          (click)="openRoomList()"
        >
          <div>
            <strong>{{ currentRoom.friendlyName }}</strong>
            <mat-icon
              class="ml-2"
              fontIcon="expand_more"
            ></mat-icon>
          </div>
        </div>
      </div>
    </mat-card>
    <app-mobile-incident-info-block
      class="flex-grow"
      [class.ml-2]="appService.screenOrientation === ScreenOrientations.LANDSCAPE"
      [spaceStats]="roomStats"
    />
  </div>

  <div class="border-b-ui-gray-light border-b flex items-center justify-between mb-3 pt-4">
    <mat-button-toggle-group
      class="mobile-toggle-group"
      (change)="tab = $event.value"
      [value]="tab"
      aria-label="Dashboard Tabs"
    >
      <mat-button-toggle [value]="Tabs.DEVICES">
        <span class="item-status-title">Devices</span>
      </mat-button-toggle>
      <mat-button-toggle [value]="Tabs.BLOCK_DIAGRAM">
        <span class="item-status-title">Block Diagram</span>
      </mat-button-toggle>
      <mat-button-toggle [value]="Tabs.INCIDENTS">
        <span class="item-status-title">Incidents</span>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div [hidden]="tab !== Tabs.DEVICES">
    <avs-li-mobile-devices-filters
      (filtersChanged)="filters.set($event)"
      [filtersPreset]="{ locationId: locationData.id, floorId: currentFloor.id, roomId: currentRoom.id }"
      [showLocationFilter]="false"
      [tableId]="TABLE_ID"
    />

    <avs-li-devices-table
      class="overflow-hidden"
      [config]="{
        isReportMode: false,
        isNoDataBigSize: false,
        isMobile: true,
      }"
      [displayedColumns]="devicesTableDisplayedColumns"
      [filters]="filters()!"
      [tableId]="TABLE_ID"
      #devicesTable
    />
  </div>
  <div [hidden]="tab !== Tabs.BLOCK_DIAGRAM">
    <app-mobile-block-diagram-tab
      [locationId]="locationId"
      [room]="currentRoom"
    />
  </div>
  <div [hidden]="tab !== Tabs.INCIDENTS">
    <app-mobile-incidents-tab
      [floorId]="floorId"
      [locationId]="locationId"
      [roomId]="currentRoom.id"
    />
  </div>
} @else {
  <app-mobile-space-skeleton-animation />
}
