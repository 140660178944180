import { Component, inject, signal, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DevicesTableV2Component, ExportDevicesSheetV2Component } from '@devices';
import { deviceColumnOptions } from '@mocks';
import { DeviceTableCols } from '@models';
import { ExportDevicesService } from '@services';

@Component({
  styleUrl: './devices-main-page.component.scss',
  templateUrl: './devices-main-page.component.html',
})
export class DevicesMainPageComponent {
  protected displayedColumns = [
    DeviceTableCols.INDEX,
    DeviceTableCols.DEVICE_NAME,
    ...deviceColumnOptions.map(column => column.value),
    DeviceTableCols.ACTIONS,
  ];
  @ViewChild('devicesTable')
  protected devicesTable!: DevicesTableV2Component;
  protected filters = signal<Record<string, string | string[]>>({});
  protected readonly TABLE_ID = 'device-main-table';

  private bottomSheet = inject(MatBottomSheet);
  private exportDevicesService = inject(ExportDevicesService);

  constructor() {
    this.exportDevicesService.export$
      .pipe(takeUntilDestroyed())
      .subscribe(type => this.exportDevices(type));
  }

  exportDevices(format: 'json' | 'csv') {
    this.devicesTable.export(format);
  }

  openExportDevicesBottomSheet() {
    return this.bottomSheet
      .open(ExportDevicesSheetV2Component)
      .afterDismissed()
      .subscribe((format?: 'csv' | 'json') => {
        if (format) {
          return (this.devicesTable as DevicesTableV2Component).export(format);
        }
      });
  }
}
