@if (device(); as device) {
  <mat-card class="card">
    <h4 class="font-poppins text-2xl text-ui-dark font-semibold">{{ device.friendlyName }}</h4>
    @if (device.tags?.['description']) {
      <p class="font-nunito font-semibold text-base text-ui-dark mt-2 mb-0">
        {{ device.tags?.['description'] }}
      </p>
    }

    @if (device.status !== EntityStatus.Archived && device.incidentCountByStatuses; as incidents) {
      <div class="test-incident-status my-3">
        @if (incidents.newCount) {
          <app-incident-status [status]="eIncidentStatus.IN_QUEUE" />
        }
        @if (!incidents.newCount && incidents.inProgressCount) {
          <app-incident-status [status]="eIncidentStatus.IN_PROGRESS" />
        }
        @if (!incidents.newCount && !incidents.inProgressCount) {
          <span class="inline-flex justify-center items-center h-6 min-w-[24px] capitalize text-xs px-2 rounded-xl text-white bg-ui-green-light">
            Good
          </span>
        }
      </div>
    }

    <div>
      @if (device.deviceModelInformation && device.deviceModelInformation.documentReference && clientId()) {
        <app-img-loader
          [imgSrc]="
            'clientId/' +
            clientId() +
            '/library/standardMake/' +
            device.deviceModelInformation.make.id +
            '/standardModel/' +
            device.deviceModelInformation.id +
            '/document/' +
            device.deviceModelInformation.documentReference
          "
          altText="device"
          errorSrc="assets/icons/image-error.svg"
          loader="skeleton"
        />
      }
      @if (!device.deviceModelInformation?.documentReference) {
        <img
          alt="Device Image"
          src="assets/icons/image-placeholder.svg"
        />
      }
    </div>

    <ul class="incident-details-list mb-3">
      <li>
        <span>Device type:</span>
        <p>{{ device.deviceModelInformation?.deviceType }}</p>
      </li>
      <li>
        <span>Manufacturer: </span>
        <p>{{ device.deviceModelInformation?.make?.name }}</p>
      </li>
      <li>
        <span>Model: </span>
        <p>{{ device.deviceModelInformation?.name }}</p>
      </li>
      <li>
        <span>Serial №: </span>
        <p>{{ device.physicalDeviceId }}</p>
      </li>
      @if (device.tags?.['ipAddress']) {
        <li>
          <span>Ip Address:</span>
          @if (device.tags?.['ipAddress'] === "N/A") {
            <p>{{ device.tags?.['ipAddress'] }}</p>
          } @else {
            <p>
              <a
                class="underline"
                [href]="'http://' + deviceIpAddress()"
                target="_blank"
              >
                {{ device.tags?.['ipAddress'] }}
              </a>
            </p>
          }
        </li>
      }
      @if (device.tags?.['macAddress']) {
        <li>
          <span>Mac Address:</span>
          <p>{{ device.tags?.['macAddress'] }}</p>
        </li>
      }

      @for (property of propertyAttributes(); track property.name) {
        @if (property.value && property.name !== 'ipAddress') {
          <li>
            <div>
              <span class="capitalize">{{ property.friendlyName || property.name }} : </span>
              <p>{{ property.value }}</p>
            </div>
          </li>
        }
      }

      @if (tempSensor()) {
        <li>
          <span>Current temperature:</span>
          <p>{{ tempSensor() }} &deg;F</p>
        </li>
      }
    </ul>

    <button
      class="mt-2 rounded border w-full py-2 flex align-middle justify-center active:bg-ui-gray-900 disabled:bg-ui-gray-900 disabled:cursor-not-allowed"
      (click)="rebootDevice()"
      [disabled]="deviceIsUpdating$ | async"
      [showForClientAdmin]="true"
      appRestrictedEl
    >
      @if (deviceIsUpdating$ | async) {
        <mat-spinner
          class="mr-1"
          diameter="20"
          matSuffix
        />
      } @else {
        <mat-icon fontIcon="refresh" />

      }
      <span class="pl-2">Reboot Device</span>
    </button>
  </mat-card>
}
